var animationSpeed = 550,
	animatedCounter = 0,
	animationDone = false,
	accordionSelector = '.element-features-video .single-accordion';

function progressBarHeight(element) {
	var triggerHeight = element.find('.accordion-trigger').outerHeight(),
		contentHeight = element.find('.content').outerHeight(),
		totalHeight = triggerHeight + contentHeight;

	element.find('.progress-bar').css('height', totalHeight);
}

function animateAccordion(index) {
	var wrapper = $(accordionSelector).eq(index),
		progress = wrapper.find('.progress-bar span'),
		animationTimeout = 5000;

	if(index !== 0) accordionClick(wrapper);

	progressBarHeight(wrapper);

	progress.animate({
		height: '100%'
	}, {
		duration: animationTimeout,
		step: function(now) {
			if (wrapper.is(":hover")) {
				progress.stop();
			}
		},
		complete: function() {
			console.log('Animation complete');

			// Increment the animated counter and check if all elements have been animated
			animatedCounter++;
			if (animatedCounter == $(accordionSelector).length) {
				console.log('All animations complete!');
				animationDone = true;
			} else {
				// Start the animation of the next accordion element
				var nextIndex = index + 1;
				if (nextIndex < $(accordionSelector).length && !animationDone) {
					animateAccordion(nextIndex);
				}
			}
		}
	});

	wrapper.on('mouseout', function() {

		progress.animate({
			height: '100%'
		}, {
			duration: (100 - progress.val()) / 100 * animationTimeout, // Resume from current value
			step: function(now) {
				if (wrapper.is(":hover")) {
					progress.stop();
				}
			},
			complete: function() {
				console.log('Resume complete');
	
				// Increment the animated counter and check if all elements have been animated
				animatedCounter++;
				if (animatedCounter == $(accordionSelector).length) {
					animationDone = true;
				} else {
					// Start the animation of the next accordion element
					var nextIndex = index + 1;
					if (nextIndex < $(accordionSelector).length && !animationDone) {
						animateAccordion(nextIndex);
					}
				}
			}
		});
	});

	accordionAnimation = progress;
}

function isAccordionExpanded(element) {
    return $(element).attr("aria-expanded") === "true";
}

function isAccordionElementInViewport() {
	$('.element-features-video').each(function () {
		var element = $(this);
		var elementPosition = element.offset().top;
		var topOfWindow = $(window).scrollTop();
		var animationStarted = element.attr('data-animation');

		if (elementPosition < topOfWindow + $(window).height() / 1.2 && animationStarted !== 'started') {
			element.attr('data-animation', 'started');

            $(accordionSelector).find('video').trigger('play');

			animateAccordion(0);
		}
	});
}

function accordionClick(element, realClick = false) {
	var parent = element.parents('.element-features-video'),
		nth = element.index(),
		animationSpeed = 550;

	if(realClick) {
		animationDone = true;

		$(accordionSelector).removeClass('clicked').find('.progress-bar').remove();
		element.addClass('clicked');
	}

	if (!isAccordionExpanded(element)) {

		parent.css('min-height', parent.outerHeight());

		$(accordionSelector)
			.attr('aria-expanded', 'false')
			.find('.content-wrapper').slideUp(animationSpeed)
			.find('video').trigger('pause');

		setTimeout(function () {

			// shows content
			element
				.attr('aria-expanded', 'true')
				.find('.content-wrapper').slideDown(animationSpeed)
				.find('video').trigger('play');

			// hides and pauses videos
			$('.videos .single')
				.attr('aria-expanded', 'false')
				.find('video').trigger('pause');

			// shows and plays videos
			$('.videos .single').eq(nth)
				.attr('aria-expanded', 'true')
				.find('video').trigger('play');

			progressBarHeight(element);

		}, animationSpeed);

		if ($(window).width() < 767) {
			setTimeout(function () {
				$([document.documentElement, document.body]).animate({
					scrollTop: element.offset().top - 55
				}, animationSpeed);
			}, animationSpeed);
		}

	}
}

$(document).ready(function () {
	isAccordionElementInViewport();
});

$(window).scroll(function() {
	isAccordionElementInViewport();
});

$(window).resize(function(){
    $('.element-features-video').removeAttr('style');

	$(accordionSelector).each(function(){
		progressBarHeight($(this));
	});
});

$(accordionSelector).click(function() {
    accordionClick($(this), true);
});